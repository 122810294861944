<template>
    <div class="user-info">
        <template v-if="info">
            <ui-list>
                <ui-list-item title="Name:">
                    <template #text>
                        {{ info.first_name }} {{ info.last_name || '' }}
                    </template>
                </ui-list-item>
                <ui-list-item v-if="info.paylink_alias" title="Payment link alias:" :text="info.paylink_alias" />
                <ui-list-item v-if="info.merchant_amex_id" title="AMEX" :text="info.merchant_amex_id" />
                <ui-list-item title="Country:" :text="info.country" />
                <ui-list-item title="Turn of trade:" text="N/A" />
                <ui-list-item title="Email:">
                    <template #text>
                        <ui-link
                            v-if="info.email"
                            weight="medium"
                            :link="`mailto:${info.email}`"
                        >
                            {{ info.email }}
                        </ui-link>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </template>
                </ui-list-item>
                <ui-list-item title="Phone:">
                    <template #text>
                        <ui-link
                            v-if="info.phone"
                            weight="medium"
                            :link="`tel:+${info.phone}`"
                        >
                            {{ info.phone }}
                        </ui-link>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </template>
                </ui-list-item>
                <ui-list-item title="Contact:">
                    <template #text>
                        <contact-links :phone="info.phone || null" :email="info.email || null" />
                    </template>
                </ui-list-item>
            </ui-list>
            <div v-if="info.clabes.length" class="list-group mt-4">
                <h6 class="mb-3">Bank transfers</h6>
                <div v-for="i in info.clabes" :key="i.id" class="list-group-item">
                    <div class="d-flex w-100 justify-content-between">
                        <small class="text-muted">Country: {{ i.country }}</small>
                        <small class="text-muted">clabe</small>
                    </div>
                    <p class="mb-1">{{ i.number }}</p>
                    <small class="text-muted">
                        <span>Bank: STP</span>
                    </small>
                </div>
            </div>
            <div v-if="info.payment_methods.length" class="list-group mt-4">
                <h6 class="mb-3">Payment methods</h6>
                <div v-for="i in info.payment_methods" :key="i.id" class="list-group-item">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">{{ i.name }}</h6>
                        <small class="text-muted">{{ i.method }}</small>
                    </div>
                    <p class="mb-1">{{ i.number || i.email }}</p>
                    <small class="text-muted">
                        <span v-if="i.business_name">Business name: {{ i.business_name }}</span>
                        <span v-else-if="i.bank">Bank: {{ i.bank }}</span>
                    </small>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ContactLinks from '@/components/ui/ContactLinks';
import UiBadge from '@/components/ui/Badge';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    components: {
        ContactLinks,
        UiBadge,
        UiLink,
        UiList,
        UiListItem,
        UiButton
    },
    props: {
        info: {
            type: Object,
            default: () => {}
        },
    },
};
</script>
